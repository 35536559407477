.machineHeader{
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    padding-left: 10px;
    /* margin-top: -0.8rem; */
}
.machineimagelogo{
    height: 2rem;
    width: 9rem;
}
.roomName{
    font-size: 1rem;
}

.machineheading{
    font-size: 0.8rem;
    /* color: gray; */
    width: 35%;
}
/* .mrsname{
    color: black;
} */
.timedate{
    padding-right: 2rem;
}
@media screen and (max-width:750px) {
    .machineheading{
        display: none;
    }
    .machineHeader{
        flex-wrap: wrap;
    }
    .roomName{
        padding-right: 2rem;
    }
    .timedate{
        padding-right: 0.5rem;
    }
}