.gobackbtn{
    background-color: transparent;
    border: 1px solid #3e4492;
    padding: 2px 7px;
    border-radius: 5px;
    width: 4rem;
    font-size: 0.7rem;
    /* position: absolute;
    right: 4rem; */
}
.gobackbtn:hover{
    background-color: #3e4492;
    border: 1px solid #3e4492;
    color: white;

}